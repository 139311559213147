<template>
  <div class="dashboard">
    <div class="hero is-success" v-if="showAlerts">
      Alerts
    </div>
    <section>
      <!-- maybe product tag list -->
    </section>

    DAshboard Contents go here
  </div>
</template>

<script>
// import Products from "@/Druware.Web.Shared/api/api";

export default {
  name: "dashboard",
  components: {},
  data: function() {
    return {
      showAlerts: false
    };
  },

  methods: {
    showError(err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: "is-bottom",
        type: "is-danger"
      });
    }
  },
  mounted() {}
};
</script>
